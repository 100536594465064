import {
  ScmAuth,
  ScmIntegrationsApi,
  scmAuthApiRef,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  OAuthApi,
  ProfileInfoApi,
  SessionApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  githubAuthApiRef,
  oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import { GithubAuth } from '@backstage/core-app-api';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { GithubTechRadarClient } from './lib/GitHubTechRadarClient';

export const ghesAuthApiRef: ApiRef<
  OAuthApi & ProfileInfoApi & SessionApi & BackstageIdentityApi
> = createApiRef({
  id: 'internal.auth.ghes',
});

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: ghesAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      GithubAuth.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'ghes',
          title: 'GitHub Enterprise Server',
          icon: () => null,
        },
        defaultScopes: ['read:user'],
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      githubAuthApi: githubAuthApiRef,
      ghesAuthApi: ghesAuthApiRef,
    },
    factory: ({ githubAuthApi, ghesAuthApi }) =>
      ScmAuth.merge(
        ScmAuth.forGithub(githubAuthApi),
        ScmAuth.forGithub(ghesAuthApi, {
          host: 'code.bbraun.io',
        }),
      ),
  }),
  createApiFactory({
    api: techRadarApiRef,
    deps: { configApi: configApiRef, scmAuthApi: scmAuthApiRef },
    factory: ({ configApi, scmAuthApi }) => new GithubTechRadarClient({configApi, scmAuthApi})
  })
];
