import { ConfigApi } from '@backstage/core-plugin-api';
import { ScmAuthApi } from '@backstage/integration-react';
import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';
import { Octokit } from '@octokit/rest';
import { readGithubIntegrationConfigs } from '@backstage/integration';

export class GithubTechRadarClient implements TechRadarApi {
  private readonly configApi: ConfigApi;
  private readonly scmAuthApi: ScmAuthApi;

  constructor(options: { configApi: ConfigApi; scmAuthApi: ScmAuthApi }) {
    this.configApi = options.configApi;
    this.scmAuthApi = options.scmAuthApi;
  }

  private async getOctokit(hostname: string = 'github.com'): Promise<Octokit> {
    const { token } = await this.scmAuthApi.getCredentials({
      url: `https://${hostname}/`,
      additionalScope: {
        customScopes: {
          github: ['repo'],
        },
      },
    });
    const configs = readGithubIntegrationConfigs(
      this.configApi.getOptionalConfigArray('integrations.github') ?? [],
    );
    const githubIntegrationConfig = configs.find(v => v.host === hostname);
    const baseUrl = githubIntegrationConfig?.apiBaseUrl;
    return new Octokit({ auth: token, baseUrl });
  }

  async load(/* id: string | undefined */): Promise<TechRadarLoaderResponse> {
    // if needed id prop can be used to fetch the correct data

    const octokit = await this.getOctokit('code.bbraun.io');

    const content  = await octokit.rest.repos.getContent({
      mediaType: {
        format: 'raw',
      },
      owner: 'dev-services',
      repo: 'dev-services-catalog',
      path: 'techradar.json',
    });

    // const data = await fetch('https://mydata.json').then(res => res.json());

    const data: any = content.data

    return JSON.parse(data);

    // For example, this converts the timeline dates into date objects
    //   return {
    //     ...data,
    //     entries: data.entries.map(entry => ({
    //       ...entry,
    //       timeline: entry.timeline.map(timeline => ({
    //         ...timeline,
    //         date: new Date(timeline.date),
    //       })),
    //     })),
    //   };
  }
}
