import React from 'react';
import { makeStyles } from '@material-ui/core';

import LogoBBraun from '../../themes/bbraun_logo.svg';

const useStyles = makeStyles({
  svg: {
    width: 170,
    height: 52,
    marginLeft: '-12px',
  },
});

const LogoIcon = () => {
  const { svg } = useStyles();

  return <img src={LogoBBraun} alt="B. Braun Logo" className={svg} />;
};

export default LogoIcon;
